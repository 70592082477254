import { graphql } from 'gatsby'
import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Link from 'gatsby-link'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import Moment from 'moment'

import Layout from '../components/Layout/Layout'
import { getLocale } from '../locales'

import './news.scss'

export default class NewsIndexPage extends React.Component {
    render() {
        const { localeSlugs, localizations } = this.props.pageContext
        const locale = getLocale(this.props.location)

        return (
            <Layout pageContext={this.props.pageContext}>
                <Container className="news-index">
                    <h1>News</h1>

                    {this.props.data.articles.edges.map(article => {
                        const link = `/${locale.shortCode}${localeSlugs['/news/'][locale.code]}${article.node.slug}`
                        const titleImage = getImage(article.node.titleImage)
                        return (<Row className="article" key={article.node.id}>
                            <Col md={4}>
                                <GatsbyImage
                                    image={titleImage}
                                    style={{ maxWidth: '100%' }}
                                    alt={article.node.titleImage.title}
                                    title={article.node.titleImage.title}
                                />
                            </Col>
                            <Col md={8}>
                                <h2>
                                    <Link to={link}>{article.node.title}</Link>
                                </h2>
                                <p className="text-justify">{article.node.teaser.teaser}</p>
                                <Link to={link}>{localizations['news']['continue-reading']}</Link>
                                <span className="pub-date float-end">
                                    {Moment(article.node.publishedAt || article.node.createdAt).format('DD.MM.YYYY')}
                                </span>
                            </Col>
                        </Row>)
                    })}
                </Container>
            </Layout>
        )
    }
}

export const pageQuery = graphql`
    query NewsIndexPageContent($locale: String!) {
        articles: allContentfulNews(
            filter: { 
                node_locale: { eq: $locale } 
                slug: { ne: null }
                title: { ne: null }
            }
            sort: { fields: [publishedAt], order: DESC }
        ) {
            edges {
                node {
                    id
                    slug
                    title
                    createdAt
                    publishedAt
                    teaser {
                        teaser
                    }
                    titleImage {
                        title
                        gatsbyImageData(layout: CONSTRAINED, width: 360, height: 240)
                    }
                }
            }
        }
    }
`
